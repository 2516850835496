<template>
    <div class="text-xs-center">
        <v-dialog v-model="dialog"
                  hide-overlay
                  persistent
                  width="300">
            <v-card color="accent"
                    dark>
                <v-card-text>
                   <span> Bitte warten</span>
                    <v-progress-linear indeterminate
                                       color="yellow"
                                       class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    export default {
        data() {
            return {

            }
        },
        watch: {

        },
        beforeMount() {

        },
        computed: {

        },
        props: ['dialog'],
        mounted() {
        },
        methods: {

        },

    }
</script>

<style scoped>
</style>
